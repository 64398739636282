<template>
  <!-- todo: buttons should disable / loading while stuff being processed (e.g.: checking if scope name exists) -->
  <sw-form-dialog
    :title="title"
    :steps="steps"
    @click:cancel="onClose"
    @click:next="validate"
    @click:submit="onSubmit"
  >
    <template v-slot:step.details>
      <v-row class="pa-10">
        <v-col cols="9">
          <v-row class="d-flex align-center mb-3">
            <v-col cols="3">
              <label>Name</label>
            </v-col>

            <v-col cols="9">
              <sw-input
                v-model:model-value="scope.name"
                placeholder="The name of this scope. It's usually the company name"
                class="w-100"
                :error="errors.name"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="3">
              <label>Account Type</label>
            </v-col>

            <v-col cols="9" class="px-3 py-0">
              <sw-autocomplete
                v-model:model-value="scope.accountType"
                placeholder="Choose an account type e.g:'Demo'"
                :items="accountTypes"
                :error-messages="errors.accountType"
                :hideDetails="false"
                @update:model-value="setHelp($event as string)"
              />
            </v-col>
          </v-row>

          <template v-if="scope.accountType === 'premium'">
            <v-row>
              <v-col cols="3">
                <label class="pb-5">License Duration</label>
              </v-col>

              <v-col cols="9" class="px-3 py-0">
                <sw-autocomplete
                  v-model="scope.licenseDuration"
                  placeholder="Choose license duration e.g:'1 year'"
                  :items="licenseDurations"
                  :error-messages="errors.licenseDuration"
                  :hideDetails="false"
                />
              </v-col>
            </v-row>

            <v-row v-if="isPartnerHeadCountBased" class="d-flex align-center my-0">
              <v-col cols="3">
                <label>Head Count</label>
              </v-col>

              <v-col cols="9">
                <sw-input
                  v-model:model-value="scope.headCount"
                  placeholder="Number of employees client has"
                  class="w-100"
                  type="number"
                  :error="errors.headCount"
                />
              </v-col>
            </v-row>
          </template>
        </v-col>
        <v-col cols="3">
          <div v-html="helpText"></div>
        </v-col>
      </v-row>
    </template>

    <template v-slot:step.primaryDomains>
      <div class="d-flex align-top mb-5">
        <label class="mt-1">Primary domain(s)</label>

        <div class="w-100">
          <sw-textarea
            v-model="seedInputVal"
            placeholder="Enter the primary domain(s). Either comma-separated, or a domain on each line."
            class="w-100"
            :error="errors.seeds"
            @update:model-value="onSeedsUpdate"
          />

          <small>
            Enter the primary domains either comma-separated, or a domain on each line.
          </small>
        </div>
      </div>
    </template>

    <template v-slot:step.users>
      <v-row>
        <v-col cols="9">
          <label>Users</label>
          <sw-autocomplete
            v-model="users"
            multiple
            :items="partnerUsersList"
            :hideSelected="false"
            chips
          />
        </v-col>

        <v-col cols="3" class="mt-6">
          <p>
            Please select the users that will have access to <strong>{{ scope.name }}</strong>
          </p>
        </v-col>
      </v-row>
    </template>

    <template v-slot:step.modules>
      <v-row>
        <v-col>
          <b v-if="premiumRequired" class="color-warning">
            You licensed a module but the scope is not premium. To be able to do this go back and
            change the account type to premium
          </b>

          <sw-scope-modules
            v-if="modules"
            :modules="modules"
            :scope="scope.name"
            :type="scope.accountType"
            @toggle-enabled="toggleModuleEnabled"
            @toggle-licensed="toggleModuleLicensed"
            @edit="editModule"
          />
          <span v-if="errors.licensedModuleRequired" class="text-red">
            {{ errors.licensedModuleRequired }}
          </span>
        </v-col>
      </v-row>
    </template>

    <template v-slot:step.overview>
      <v-row>
        <v-col>
          <h5>Name</h5>

          <span>{{ scope.name }}</span>
        </v-col>

        <v-col>
          <h5>Account Type</h5>

          <span>{{ scope.accountType }}</span>
        </v-col>

        <template v-if="scope.accountType === 'premium'">
          <v-col>
            <h5>License Duration</h5>

            <span>1 {{ scope.licenseDuration }}</span>
          </v-col>

          <v-col>
            <h5>Head Count</h5>

            <span>{{ scope.headCount }}</span>
          </v-col>
        </template>

        <v-col>
          <h5>Users</h5>

          <template v-if="users.length">
            <span v-for="user in users" :key="user">{{ user }}<br /></span>
          </template>

          <span v-else>-</span>
        </v-col>

        <sw-tooltip v-if="errors.details" :tooltip="errors.details">
          <v-icon size="small" class="ml-1 color-error"> fa-regular fa-exclamation </v-icon>
        </sw-tooltip>
      </v-row>

      <div class="mt-3">
        <h5>Primary domain(s):</h5>

        <sw-scope-create-dialog-seed-list
          :seeds="scope.seeds"
          :modules="enabledModules"
          @click="removeSeed"
          @update:seed-module="onSeedModuleUpdate"
        />

        <small v-if="!hasSelectedSeeds" class="color-warning">
          Note: If you have not selected at least one primary domain, no scans will be performed.
        </small>
      </div>

      <div class="mt-3" v-if="enabledModules.length">
        <h5>Modules:</h5>

        <table width="50%">
          <thead height="30px">
            <th>Module</th>
            <th width="20%">Enabled</th>
            <th width="20%">Licensed</th>
          </thead>
          <tbody>
            <tr v-for="(module, index) in enabledModules" :key="index">
              <td>
                {{ capitalizeModule(module.name) }}
              </td>
              <td>
                <v-icon size="x-small" class="ml-2 mt-n1">
                  fa-regular {{ module.enabled || module.licensed ? "fa-check" : "fa-xmark" }}
                </v-icon>
              </td>
              <td>
                <v-icon size="x-small" class="ml-2 mt-n1">
                  fa-regular
                  {{
                    module.licensed || (module.name === "outscan" && module.enabled)
                      ? "fa-check"
                      : "fa-xmark"
                  }}
                </v-icon>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </template>

    <template v-slot:extra-action>
      <v-btn variant="text" v-if="currentStep !== 1" @click="previousStep"> Previous </v-btn>

      <template v-if="currentStep === 3">
        <v-btn variant="text" @click="addAllUsers">Add All</v-btn>
        <v-btn variant="text" @click="removeAllUsers">Remove All</v-btn>
      </template>
    </template>
  </sw-form-dialog>

  <sw-scope-module-license-dialog
    v-if="modules && selectedModuleType"
    :modules="modules"
    :open="showLicenseDialog"
    :selectedModuleType="selectedModuleType"
    :selectedModule="selectedModule!"
    :is-edit="isEdit"
    :is-license="isLicense"
    @close="closeLicenseDialog"
    @submit="handleSaveModuleConfig"
  />
</template>

<script setup lang="ts">
  import dayjs from "dayjs";
  import { SwInput, SwTextarea, SwTooltip, SwAutocomplete } from "sweepatic-shared/components";
  import { computed, getCurrentInstance, ref, watch } from "vue";
  import type { KeyObject } from "sweepatic-shared/typings/misc";
  import { useRoute, useRouter } from "vue-router";

  import SwFormDialog from "@/components/common/SwFormDialog.vue";
  import SwScopeCreateDialogSeedList from "@/components/scopes/SwScopeCreateDialogSeedList.vue";
  import SwScopeModuleLicenseDialog from "@/components/scopes/SwScopeModuleLicenseDialog.vue";
  import SwScopeModules from "@/components/scopes/SwScopeModules.vue";
  import { useStore } from "@/composables/store";
  import { reportSuccess, reportUnexpectedError } from "@/EventBus";
  import { addScope, getScopeModules, getScope } from "@/services/scopes.service";
  import type {
    LicenseDuration,
    ModuleConfig,
    ScopeCreatePayload,
    ScopeModuleConfig,
    ScopeModuleWithName,
    ScopeModule,
    Session,
    TitleValue,
    UpgradePayload,
  } from "@/typings";
  import { ModuleType } from "@/typings";
  import type { Seed } from "@/typings/scope";
  import { isDomainValid, normalizeTextareaInput } from "@/utils";
  import { capitalizeModule } from "@/utils/formatters";
  import { EDITABLE_MODULES } from "./constants";

  const ERRORS: Record<string, string> = {
    scope_required: "Name is required",
    scope_exists: "This scope already exists. Please contact customer support",
    disabled_scope_exists:
      "This scope already exists but is disabled. Please contact customer support",
    scope_small: "Name needs at least 3 characters",
    seeds_invalid_format: "Invalid domain format",
    seeds_exists:
      "This domain is already associated with another scope. Please change or remove the domain. <br/>Contact customer support for any questions on this",
    head_count:
      "This scope has a head count based license. Please specify the number of employees of the client",
    account_type_required: "Please select an account type",
    license_duration_required: "Please select a duration for your scope",
    licensed_module_required: "Some module must be licensed with the Premium account type",
  };

  const HELP_TEXT: Record<string, string> = {
    premium:
      "A premium scope is a paid scope with no limitations. All standard functionality Sweepatic offers is present.  <br /> <br />The cost of this scope will be added to your next invoice.",
    demo: "A demo version of the platform. <br /> <br />This is free but is limited in functionality.",
  };

  interface ScopeCreateForm {
    name: string;
    accountType: string;
    licenseDuration?: LicenseDuration;
    headCount: string;
    seeds: Seed[];
  }

  interface Errors {
    name?: string;
    headCount?: string;
    accountType?: string;
    licenseDuration?: string;
    seeds?: string;
    details?: string;
    licensedModuleRequired?: string;
  }

  const $store = useStore();
  const $router = useRouter();
  const $route = useRoute()

  const $emit = defineEmits<{
    (e: "click:close", payload: boolean): void;
  }>();

  const steps: TitleValue[] = [
    { title: "Details", value: "details" },
    { title: "Primary Domains", value: "primaryDomains" },
    { title: "Access Control", value: "users" },
    { title: "Modules", value: "modules" },
    { title: "Overview", value: "overview" },
  ];

  const modules = ref<ScopeModuleConfig>({});
  const users = ref<string[]>([]);
  const showLicenseDialog = ref<boolean>(false);
  const selectedModuleType = ref<ModuleType>();
  const selectedModule = ref<ScopeModule>();
  const seedInputVal = ref<string>("");
  const errors = ref<Errors>({});
  const title = ref<string>("Create new scope");
  const isEdit = ref<boolean>(false);
  const isLicense = ref<boolean>(false);
  const isValidating = ref<boolean>(false);
  const enabledModules = ref<ScopeModuleWithName[]>([]);
  const premiumRequired = ref<boolean>(false);
  const helpText = ref("");
  const licenseDurations = ref<TitleValue[]>([
    { value: "month", title: "1 Month" },
    { value: "year", title: "1 Year" },
  ]);
  const scope = ref<ScopeCreateForm>({
    name: "",
    accountType: "",
    licenseDuration: undefined,
    headCount: "",
    seeds: [],
  });

  const session = computed<Session>(() => $store.getters["session/session"]);

  const isPartnerHeadCountBased = computed<boolean>(
    () => $store.getters["session/isPartnerHeadCountBased"],
  );

  const partnerContractType = computed<string>(() => $store.getters["session/partnerContractType"]);

  const partner = computed<string>(() => $store.getters["session/partner"]);

  const hasProspectScopes = computed<boolean>(() => $store.getters["session/hasProspectScopes"]);

  const hasPocScopes = computed<boolean>(() => $store.getters["session/hasPocScopes"]);

  const hasPremiumScopes = computed<boolean>(() => $store.getters["session/hasPremiumScopes"]);

  const currentStep = computed<number>(() => $store.getters["formDialog/currentStep"]);

  const partnerUsersList = computed<KeyObject[]>(() => $store.getters["users/partnerUsersList"]);

  const hasSelectedSeeds = computed<boolean>(() => {
    return scope.value.seeds.some((s) => s.isSeed);
  });

  const accountTypes = computed<TitleValue[]>(() => {
    return [
      ...(hasPremiumScopes.value ? [{ value: "premium", title: "Premium" }] : []),
      { value: "demo", title: "Demo" },
      ...(hasProspectScopes.value ? [{ value: "prospect", title: "Prospect" }] : []),
      ...(hasPocScopes.value ? [{ value: "PoC", title: "PoC" }] : []),
    ];
  });

  const getPartnerUsers = (name: string) => $store.dispatch("users/getPartnerUsers", name);

  const nextStep = () => $store.commit("formDialog/nextStep");

  const previousStep = () => $store.commit("formDialog/previousStep");

  const resetCurrentStep = () => $store.commit("formDialog/resetCurrentStep");

  const setDisabled = (disabled: boolean) => $store.commit("formDialog/setDisabled", disabled);

  const setLoading = (loading: boolean) => $store.commit("formDialog/setLoading", loading);

  const validate = async () => {
    if (currentStep.value === 1) {
      (await validateDetails()) && nextStep();
      return;
    }

    if (currentStep.value === 2) {
      (await validateSeeds()) && nextStep();
      return;
    }

    if (currentStep.value === 3) {
      nextStep();
      getScopeModules(scope.value.name, true, partner.value).then((data) => {
    if (!$route.query.dark_web) {
      delete data.dark_web;
    }

    modules.value = data;
  });
      return;
    }

    if (currentStep.value === 4) {
      setEnabledModules();
      validateModules() && nextStep();
    }
  };

  const onSubmit = async () => {
    const payload: ScopeCreatePayload = {
      name: scope.value.name.trim(),
      account_type: scope.value.accountType,
      account_lead: session.value.email,
      seeds: scope.value.seeds,
      partner_name: session.value.partner.name,
      users: users.value,
      partner: true,
      modules: enabledModules.value.reduce<ScopeModuleConfig>((result, m) => {
        result[m.name as keyof ScopeModuleConfig] = {
          enabled: m.enabled,
          licensed: m.licensed,
          config: m.config,
          created: m.created,
        };

        return result;
      }, {}),
    };

    if (scope.value.accountType === "premium") {
      payload.contract_type = partnerContractType.value;
      payload.head_count = scope.value.headCount;
      payload.license_duration = scope.value.licenseDuration;
      payload.start_date = dayjs().format("YYYY-MM-DD");
      payload.end_date = dayjs().add(1, scope.value.licenseDuration).format("YYYY-MM-DD");
    }

    isValidating.value = true;
    setLoading(true);

    try {
      const scope = await addScope(payload);
      $router.push({ name: "scope-editor", params: { scope } });

      reportSuccess(`Scope ${scope} created`);
      setLoading(false);
      onClose();
    } catch (err: any) {
      isValidating.value = false;
      setLoading(false);

      if (!err || err.status !== 400) {
        return reportUnexpectedError(err);
      }

      const error = err.error;

      if (error === "Must be premium account to license modules") {
        premiumRequired.value = true;
        previousStep();
        return setDisabled(true);
      }

      if (
        error === "You have reached the maximum allowed primary domains for module credentials."
      ) {
        return reportError(error);
      }

      if (error.scope_exists) {
        return (errors.value.details = ERRORS.scope_exists);
      }

      if (error.disabled_scope_exists) {
        return (errors.value.details = ERRORS.disabled_scope_exists);
      }

      if (error.scope_small) {
        return (errors.value.details = ERRORS.scope_small);
      }

      // domain validation errors
      scope.value.seeds = scope.value.seeds.map((s) => {
        if (error.domain_exists.includes(s.domain)) {
          s.error = ERRORS.seeds_exists;
        } else if (error.invalid_format.includes(s.domain)) {
          s.error = ERRORS.seeds_invalid_format;
        } else {
          s.error = "";
        }

        return s;
      });

      if (error.domain_exists.length) {
        errors.value.seeds = `The following domains are associated with another scope: <b>${error.domain_exists.join(
          ",",
        )}</b>. Please change or remove the domain. Contact customer support for any questions on this`;
      }

      if (error.invalid_format.length) {
        errors.value.seeds += `${
          errors.value.seeds ? "<br/>" : ""
        }The Following domains have invalid format: <b>${error.invalid_format.join(",")}</b>`;
      }

      getCurrentInstance()?.proxy?.$forceUpdate();
    }
  };

  const validateDetails = async () => {
    const name = (scope.value.name || "").trim();
    const err: Errors = {};

    if (!name) {
      err.name = ERRORS.scope_required;
    } else if (name.length < 3) {
      err.name = ERRORS.scope_small;
    }

    if (!scope.value.accountType) {
      err.accountType = ERRORS.account_type_required;
    }

    if (scope.value.accountType === "premium") {
      if (isPartnerHeadCountBased.value && !scope.value.headCount) {
        err.headCount = ERRORS.head_count;
      }

      if (!scope.value.licenseDuration) {
        err.licenseDuration = ERRORS.license_duration_required;
      }
    }

    if (Object.keys(err).length) {
      errors.value = err;
      return false;
    }

    isValidating.value = true;

    const result = await getScope(partner.value, name);

    if (result) {
      errors.value = { name: ERRORS[result] };
      isValidating.value = false;
      getCurrentInstance()?.proxy?.$forceUpdate();
      return false;
    }

    return true;
  };

  const onSeedsUpdate = async (value: string) => {
    scope.value.seeds = normalizeTextareaInput(value).map<Seed>((s) => ({
      domain: s,
      isSeed: true,
      totalSubdomains: 0,
    }));
  };

  const validateSeeds = async () => {
    // todo: there's a bug if i start typing something and then clear input
    // it'll still try to validate against that input
    if (!scope.value.seeds.length) {
      return true;
    }

    isValidating.value = true;
    const err: Errors = {};

    const invalidDomains = scope.value.seeds.reduce<string[]>((result, { domain }) => {
      if (!isDomainValid(domain)) {
        result.push(`"${domain}"`);
      }

      return result;
    }, []);

    if (invalidDomains.length) {
      err.seeds = `The following domains are invalid: ${invalidDomains.join(", ")}`;
    }

    errors.value = err;

    isValidating.value = false;

    return !Object.keys(errors.value).length;
  };

  const removeSeed = (domain: string) => {
    scope.value.seeds = scope.value.seeds.filter((s) => s.domain !== domain);
    seedInputVal.value = seedInputVal.value
      .replace(domain, "")
      .trim()
      // remove "," if it's first character
      .replace(/^,+/, "")
      // remove "," if it's last character
      .replace(/,$/, "")
      .trim();
  };

  const validateModules = () => {
    const err: Errors = {};

    if (scope.value.accountType === "premium") {
      const anyLicensedModule = Object.values(modules.value).some((mod) => mod.licensed);
      if (!anyLicensedModule) {
        err.licensedModuleRequired = ERRORS.licensed_module_required;
      }
    }

    errors.value = err;

    return !Object.keys(errors.value).length;
  };

  const setEnabledModules = () => {
    const enabledMods: ScopeModuleWithName[] = [];
    const enabledModsName: string[] = [];
    const enabledModuleNames: Record<string, boolean> = {};

    Object.entries(modules.value || {}).forEach(([modName, module]) => {
      if (!module.enabled || modName === ModuleType.outscan) {
        return;
      }

      enabledMods.push({ name: modName, ...module });
      enabledModsName.push(modName);
      enabledModuleNames[modName] = true;
    });

    // Always include easm
    if (!(ModuleType.easm in enabledModuleNames)) {
      enabledModuleNames[ModuleType.easm] = true;
      enabledModsName.push(ModuleType.easm);
    }

    scope.value = {
      ...scope.value,
      seeds: scope.value.seeds.map((s) => ({
        ...s,
        ...enabledModuleNames,
        modules: enabledModsName,
      })),
    };
    enabledModules.value = enabledMods;
  };

  const onClose = () => {
    $emit("click:close", false);
    resetCurrentStep();
  };

  const addAllUsers = () => {
    users.value = partnerUsersList.value.map((u) => u.value);
  };

  const removeAllUsers = () => {
    users.value = [];
  };

  const toggleModuleEnabled = (moduleType: ModuleType) => {
    isEdit.value = false;
    isLicense.value = false;
    if (
      !modules.value[moduleType]!.enabled &&
      EDITABLE_MODULES.includes(moduleType) &&
      !modules.value[moduleType]!.config
    ) {
      selectedModuleType.value = moduleType;
      showLicenseDialog.value = true;
    } else {
      handleSaveModuleConfig(moduleType);
    }
  };

  const toggleModuleLicensed = ({ moduleType }: { moduleType: ModuleType }) => {
    isEdit.value = false;
    isLicense.value = true;
    if (
      !modules.value[moduleType]!.licensed &&
      EDITABLE_MODULES.includes(moduleType) &&
      !modules.value[moduleType]!.config
    ) {
      selectedModuleType.value = moduleType;
      showLicenseDialog.value = true;
    } else {
      handleSaveModuleConfig(moduleType);
    }
  };

  const editModule = (payload: { moduleType: ModuleType; module: ScopeModule }) => {
    isEdit.value = true;
    selectedModuleType.value = payload.moduleType;
    selectedModule.value = payload.module;
    showLicenseDialog.value = true;
  };

  const closeLicenseDialog = () => {
    showLicenseDialog.value = false;
  };

  const handleSaveModuleConfig = (
    moduleType: ModuleType,
    _?: UpgradePayload,
    configPayload?: ModuleConfig | null,
  ) => {
    const mod = modules.value[moduleType]!;

    if (configPayload) {
      mod.config = configPayload;
    }

    if (isEdit.value) {
      showLicenseDialog.value = false;
      modules.value[moduleType] = mod;
      return;
    }

    if (isLicense.value) {
      if (!mod.licensed) {
        mod.enabled = true;
        mod.licensed = true;
      } else {
        mod.licensed = false;
      }
    } else {
      if (mod.enabled) {
        mod.licensed = false;
        mod.enabled = false;
      } else {
        mod.enabled = true;
      }
    }

    modules.value[moduleType] = mod;
    showLicenseDialog.value = false;
  };

  const setHelp = (type: string) => (helpText.value = HELP_TEXT[type] || "");

  const onSeedModuleUpdate = (seed: Seed) => {
    scope.value = {
      ...scope.value,
      seeds: scope.value.seeds.map((s) => {
        return s.domain === seed.domain ? seed : s;
      }),
    };
  };

  getPartnerUsers(partner.value);

  watch(
    modules,
    (val) => {
      if (val) {
        setEnabledModules();

        if (scope.value.accountType !== "premium") {
          const anyLicensedModule = Object.values(modules.value).some((mod) => mod.licensed);

          // licensed at least 1 module
          if (anyLicensedModule) {
            premiumRequired.value = true;
            setDisabled(true);
          } else {
            premiumRequired.value = false;
            setDisabled(false);
          }
        }
      }
    },
    { deep: true, immediate: true },
  );

  watch(currentStep, (current, prev) => {
    // reenable next button if we're going backwards after premium required error
    if (current < prev && premiumRequired.value) {
      setDisabled(false);
    }
  });

  watch(
    () => scope.value.accountType,
    (type) => {
      if (type === "premium" && premiumRequired.value) {
        premiumRequired.value = false;
      }
    },
  );
</script>

<style lang="scss" scoped>
  label {
    width: 20%;
  }

  table {
    tbody::before {
      content: "@";
      display: block;
      line-height: 10px;
      text-indent: -99999px;
    }

    th {
      padding-left: 8px;
      font-size: 14px;
      text-align: left;
      border: solid;
      border-width: 1px 0;
      border-top: none;
      border-color: lightgray;
    }

    td {
      padding-left: 8px;
      font-size: 14px;
      text-align: left;
    }
  }
</style>
